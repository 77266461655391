<template>
  <div>
    <v-tabs v-model="activeTab">

        <v-tab v-for="(tab, tabIndex) in config.tabs" :key="tabIndex+'tabinregisterTitle'"
        @click="tabChangedEvent(tabIndex)">{{ tab.title }}
      </v-tab>
      <v-tab-item v-for="(tab, tabIndex) in config.tabs" :key="tabIndex+'tabinregisterContents'"
                  transition="fade-transition" reverse-transition="fade-transition">
        <RegisterTab :ref="'registerTab_' + tabIndex" :config="tab.config" :select-mode="selectMode"
                     @select="$emit('select', $event)"></RegisterTab>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import RegisterTab from './RegisterTab.vue';
import {getQueryObject} from "@/modules/CommonUtils";

export default {
  name: "Register",
  //конфиг - джсон с конфигурацией реестра, pickMode - запускаем ли в режиме выбора записи
  props: ["config", "selectMode", "presetActiveTab"],
  components: {RegisterTab},
  data() {
    return {
      activeTab: 0
    };
  },
  filters: {},
  methods: {
    tabChangedEvent(tabIndex) {
      const urlParams = {}
      urlParams._tab = tabIndex
      if (!this.selectMode)
        this.$router.push({path: window.location.pathname, query: urlParams})
    },
  },
  beforeMount() {
    if(!this.selectMode) {
      const queryTab = getQueryObject()._tab
      if(queryTab) {
        this.activeTab = +queryTab
      }
    } else if(this.presetActiveTab != null){
      this.activeTab = +this.presetActiveTab
    }
  },
}
</script>

<style scoped>

::v-deep .v-tabs-bar {
  max-width: 70%;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  letter-spacing: normal;
  padding: 0 10px;
}
</style>